<script setup lang="ts">
useHead({
  title: 'Page Not Found - Refloat',
})
</script>

<template>
  <main class="h-full">
    <slot />
  </main>
</template>
